<template>
  <div>
    <v-autocomplete
      :prepend-icon="icon ? icons.mdiAlphaCCircle : null"
      :clearable="clearable"
      hide-details="auto"
      :disabled="disabled"
      v-model="select"
      :items="lista"
      :label="label"
      item-value="id"
      item-text="descripcion"
      :dense="outlined"
      :outlined="outlined"
      :loading="cargando"
      :append-outer-icon="add ? icons.mdiPlusCircleOutline : null"
      @click:append-outer="abrirModal()"
      :rules="rules"
    >
    </v-autocomplete>
  </div>
</template>
<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import { mdiAlphaCCircle, mdiPlusCircleOutline } from '@mdi/js'   
import MermaServices  from '@/api/servicios/inventario/MermaServices'
export default {
  components: { 
  },
  props: {
    value: String, 
    disabled: Boolean,
    dense: Boolean,
    outlined: Boolean,
    icon: Boolean,
    todos: Boolean,
    default: Number,
    clearable: Boolean,
    padre: {
      type: Number,
      default: -1,
    },
    rules: Array,
    add: {
      type: Boolean,
      default: true,
    },
    label:{
      type: String,
      default:"Tipo Merma"
    }
  },
  watch:{
    padre : function(){
        
        this.cargarDatos()
       
    }
  },
  setup(props, contex) {
    const lista = ref([])
    const select = ref(null)
    const cargando = ref(false)
    const dialog = ref(false)

    watch(props, () => {
      select.value = props.value
    })
    onBeforeMount(() => {
      cargarDatos()
      if (props.default != undefined) {
        select.value = props.default
      }
    })

    const GetActualizarLista = item => {
      lista.value.push(item)
      select.value = item.id
      dialog.value = false
    }
    const abrirModal = () => {
      dialog.value = true
    }
    const cargarDatos = () => {
      lista.value = []
      cargando.value = true
      try {
     
        MermaServices.listarTipoMerma()
          .then(response => {
            console.log(response)
            if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
              lista.value = response.data.datos
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            cargando.value = false
          })
      } catch (error) { 
        cargando.value = false
      }
    }
 
    watch(select, () => {
      const valor = lista.value.find(item => item.id == select.value)

      if (props.jsonRespueta == true) {
        contex.emit('input', valor == undefined ? null : valor)
      } else contex.emit('input', select.value)
    })

    return {
      icons: {
        mdiAlphaCCircle,
        mdiPlusCircleOutline,
      },
      lista,
      select,
      cargando,
      dialog,
      abrirModal,
      GetActualizarLista,
      cargarDatos
    }
  },
}
</script>